


















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import KeyElementsEdit from '@bertazzoni/back/components/KeyElementsEdit.vue'
import ContactEdit from '@bertazzoni/back/components/ContactEdit.vue'
import UploadFile from '@bertazzoni/back/components/UploadFile.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import QuizEdit from '@bertazzoni/back/components/QuizEdit.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import ConfirmationModal from '@bertazzoni/back/components/ConfirmationModal.vue'
import AssociateContent from '@bertazzoni/back/components/AssociateContent.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import AccessFilter from '@bertazzoni/back/components/AccessFilter.vue'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import TagsService from '@bertazzoni/common/src/services/TagsService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import { FileTypeFormat } from '@bertazzoni/common/src/helpers/utils/fileManager'
import { NewModuleDto, Contact } from '@bertazzoni/common/src/models/module.model'
import { Tag } from '@bertazzoni/common/src/models/tag.model'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { Content } from '@bertazzoni/common/src/models/content.model'
import { Question } from '@bertazzoni/common/src/models/Question'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'
@Component({
  components: {
    Loader,
    FormError,
    Icons,
    UploadFile,
    DatePicker,
    KeyElementsEdit,
    ContactEdit,
    PaginatedTable,
    ConfirmationModal,
    AssociateContent,
    QuizEdit,
    TagList,
    AccessFilter
  }
})
export default class AddModule extends Vue {
  private newModule: NewModuleDto = new NewModuleDto()
  private pageLoaded = false
  private submitLoading = false
  private formatFile = FileTypeFormat
  private imageLoad = false
  private selectedDelete: { id: string; name: string; type: string }
  private showDeleteModal = false
  private contentList: Content[] = []
  private languageList: string[] = []
  private submitError = {
    date: false,
    quiz: false,
    tag: false,
    image: false,
    content: false,
    module: false,
    keyElement: false,
    contact: false,
    accessEntity: false
  }
  private tableInfoContent: TableInfo = new TableInfo()
  private tableInfoQuiz: TableInfo = new TableInfo()
  private questionToEdit: Question = null
  private searchQuizInput = ''
  private showNewQuestion = false
  private showContentModal = false
  private keyDate = 0
  private keyTable = 0
  private tagToSelectList: string[] = []

  async created(): Promise<void> {
    this.setTableInfo()
    this.languageList = await getLanguageList(true)
    await this.getTagList()
    await this.getContentList()
    this.pageLoaded = true
  }
  async setTableInfo(): Promise<void> {
    this.tableInfoContent.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().displayButton = false
  }
  async getContentList(): Promise<void> {
    if (this.newModule.contentIDs && this.newModule.contentIDs.length > 0) {
      this.contentList = await ContentService.findByIds(this.newModule.contentIDs)
    } else {
      this.contentList = []
    }
  }
  async getTagList(): Promise<void> {
    this.tagToSelectList = []
    try {
      const tagList = await TagsService.findAll()
      tagList.map((tag: Tag) =>
        tag.tags.map((tagName: string) =>
          this.tagToSelectList.push(`${tag.tagCategory}_${tagName}`)
        )
      )
    } catch (e) {
      if (!isErrorNotFound(e)) {
        throw e
      }
    }
  }
  deleteObject(): void {
    if (this.selectedDelete.type === 'content') {
      this.newModule.contentIDs.splice(this.newModule.contentIDs.indexOf(this.selectedDelete.id), 1)
      this.getContentList()
    } else {
      this.newModule.quiz.splice(parseInt(this.selectedDelete.id) - 1, 1)
      this.actualiseQuestionId()
    }
    this.showDeleteModal = false
  }
  removeContent(id: string, name: string): void {
    this.selectedDelete = { id: id, name: name, type: 'content' }
    this.showDeleteModal = true
    this.checkErrorQuiz()
  }
  openModalContent(): void {
    if (this.isLanguageFill) this.showContentModal = true
  }
  get isLanguageFill() {
    return this.newModule.language !== ''
  }
  associateContents(contentList: string[]): void {
    this.newModule.contentIDs = contentList
    this.getContentList()
    this.showContentModal = false
    this.checkErrorContent()
  }
  editQuestion(object: Question): void {
    this.questionToEdit = object
    this.showNewQuestion = true
  }
  selectTag(tag: string): void {
    this.newModule.tags.push(tag)
  }
  removeTag(tag: string): void {
    this.newModule.tags.splice(this.newModule.tags.indexOf(tag), 1)
  }
  removeQuestion(id: string, name: string): void {
    this.selectedDelete = { id: id, name: name, type: 'question' }
    this.showDeleteModal = true
    this.keyTable++
    this.checkErrorQuiz()
  }
  openNewQuestion(): void {
    this.questionToEdit = null
    this.showNewQuestion = true
  }
  addNewQuestion(newQuestion: Question): void {
    newQuestion.id = this.newModule.quiz ? this.newModule.quiz.length + 1 : 1
    this.newModule.quiz
      ? this.newModule.quiz.push(newQuestion)
      : (this.newModule.quiz = [newQuestion])
    this.showNewQuestion = false
    this.questionToEdit = null
    this.keyTable++
    this.checkErrorQuiz()
  }
  editNewQuestion(editQestion: Question): void {
    const i = this.newModule.quiz.findIndex((quest: Question) => quest.id === editQestion.id)
    this.newModule.quiz.splice(i, 1, editQestion)
    this.showNewQuestion = false
    this.questionToEdit = null
    this.keyTable++
    this.checkErrorQuiz()
  }
  actualiseQuestionId(): void {
    for (let index = 0; index < this.newModule.quiz.length; index++) {
      this.newModule.quiz[index].id = index + 1
    }
    this.keyTable++
  }
  getStartingDate(newDate: Date): void {
    this.newModule.startingDate = newDate ? new Date(newDate) : null
    this.keyDate++
  }
  getExpirationDate(newDate: Date): void {
    this.newModule.expirationDate = newDate ? new Date(newDate) : null
    this.keyDate++
  }
  imageUpload(loading: boolean): void {
    this.imageLoad = loading
  }
  getImage(newImage: string): void {
    this.newModule.imageUrl = newImage
    this.checkErrorImg()
  }
  setKeyElement(newKeyElement: string[]): void {
    this.newModule.keyBenefits = newKeyElement
    this.checkErrorKeyElements()
  }
  setContactList(newContactList: Contact[]): void {
    this.newModule.contacts = newContactList
    this.checkErrorContact()
  }
  editAccessList(newAccessList: string[]): void {
    this.newModule.accessEntityIds = newAccessList
    this.checkErrorAccessEntity()
  }

  get validity(): boolean {
    return this.formValid()
  }

  formValid(): boolean {
    const isValid = this.$validator.validateAll()
    this.checkErrorDate()
    this.checkErrorImg()
    this.checkErrorContent()
    this.checkErrorQuiz()
    this.checkErrorKeyElements()
    this.checkErrorContact()
    this.checkErrorTag()
    this.checkErrorAccessEntity()
    return (
      isValid &&
      !this.submitError.quiz &&
      !this.submitError.image &&
      !this.submitError.content &&
      !this.submitError.module &&
      !this.submitError.contact &&
      !this.submitError.keyElement &&
      !this.submitError.tag &&
      !this.submitError.date &&
      !this.submitError.accessEntity
    )
  }

  checkErrors(): void {
    if (this.formValid()) {
      this.submitNewModule()
    }
  }
  checkErrorDate(): void {
    if (!this.newModule.startingDate || this.newModule.startingDate.toString() === '') {
      this.submitError.date = true
    } else {
      this.submitError.date = false
    }
  }
  checkErrorTag(): void {
    if (!this.newModule.tags || this.newModule.tags.length === 0) {
      this.submitError.tag = true
    } else {
      this.submitError.tag = false
    }
  }
  checkErrorAccessEntity(): void {
    if (!this.newModule.accessEntityIds || this.newModule.accessEntityIds.length === 0) {
      this.submitError.accessEntity = true
    } else {
      this.submitError.accessEntity = false
    }
  }
  checkErrorKeyElements(): void {
    if (this.newModule.keyBenefits && this.newModule.keyBenefits.length > 0) {
      this.submitError.keyElement = false
    } else {
      this.submitError.keyElement = true
    }
  }
  checkErrorImg(): void {
    if (!this.newModule.imageUrl || this.newModule.imageUrl === '') {
      this.submitError.image = true
    } else {
      this.submitError.image = false
    }
  }
  async checkErrorContact(): Promise<void> {
    if (this.newModule.contacts.length > 0) {
      const validList = []
      for (let index = 0; index < this.newModule.contacts.length; index++) {
        validList.push(this.$validator.validate(`contactName${index}`))
        validList.push(this.$validator.validate(`email${index}`))
      }
      const results = Promise.all(validList)
      this.submitError.contact = !(await results).every((isValid) => isValid)
    } else {
      this.submitError.contact = false
    }
  }
  checkErrorQuiz(): void {
    if (!this.newModule.quiz || this.newModule.quiz.length !== 5) {
      this.submitError.quiz = true
    } else {
      this.submitError.quiz = false
    }
  }
  checkErrorContent(): void {
    if (
      !this.newModule.contentIDs ||
      this.newModule.contentIDs.length < 1 ||
      this.newModule.contentIDs.length > 5
    ) {
      this.submitError.content = true
    } else {
      this.submitError.content = false
    }
  }
  async submitNewModule(): Promise<void> {
    this.changeLoading()
    try {
      await ModulesService.createOne(this.newModule)
      this.changeLoading()
      this.$router.push('/module-list')
    } catch {
      this.changeLoading()
    }
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
